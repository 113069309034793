<template>
  <div>
    <DonationProductsList ref="listProductsDialog"></DonationProductsList>
    <v-layout>
      <UseTermsModal v-model="showServiceTerms"></UseTermsModal>
      <PrivacyPolicyTermsModal v-model="showPolicyTerms"></PrivacyPolicyTermsModal>
      <div class="boxapp no-help mr-0 bg-default">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ project.type }}</h1>
        </div>

        <!-- <div class="prestacao-e-social">
          <v-container fluid py-2>
            <v-layout class="justify-space-between">
              <div class="display-flex align-center">
                <router-link
                  :to="{ name: routes.web.ProjectAccountabilityLP, query: { projectId: projectLP.project.id }}"
                >
                  <Icon class="fas fa-usd-circle mr-2" size="18" colorFont="#fff" /><span>Prestação de contas</span>
                </router-link>
              </div>
              <div class="display-flex align-center">
                <span class="compartilhe">compartilhe</span>
                <a :href="GetSocialMediaLink(SocialMedia.Facebook, null, 'vazio', shareLink)">
                  <Icon class="fab fa-facebook-f mx-3" size="18" />
                </a>
                <a :href="GetSocialMediaLink(SocialMedia.Whatsapp, null, 'vazio', shareLink)">
                  <Icon class="fab fa-whatsapp mx-3" size="18" />
                </a>
                <a :href="GetSocialMediaLink(SocialMedia.Linkedin, null, 'vazio', shareLink)">
                  <Icon class="fab fa-linkedin-in mx-3" size="18" />
                </a>
                <a :href="GetSocialMediaLink(SocialMedia.Twitter, null, 'vazio', shareLink)">
                  <Icon class="fab fa-twitter mx-3" size="18" />
                </a>
              </div>
            </v-layout>
          </v-container>
        </div>-->

        <div class="section hero-preview">
          <ProjectHeroLP
            :projectLP="projectLP"
            :colorPrimary="color_primary"
            :colorSecondary="color_secondary"
            :showDonationButton="showDonationButton"
            :campaignLandingPage="campaignLandingPage"
            @scrollMeTo="scrollMeTo"
          />
          <div class="publico project-lp mb-4">
            <div class="container py-0 display-flex justify-center d-row">
              <div class="card">
                <i class="fal fa-bullseye-arrow"></i>
                <h4 class="color-theme-primaria">Público Alvo</h4>
                <h5
                  class="color-theme-texto"
                >{{ getTargetAudienceName(projectLP.project.targetAudienceId) }}</h5>
              </div>
              <div class="card mx-3">
                <i class="fal fa-user-circle"></i>
                <h4 class="color-theme-primaria">Causa</h4>
                <h5 class="color-theme-texto">{{ projectLP.project.cause.name }}</h5>
              </div>
              <div class="card">
                <i class="fal fa-hammer"></i>
                <h4 class="color-theme-primaria">Fase</h4>
                <h5
                  class="color-theme-texto"
                >{{ getProjectPhaseName(projectLP.project.projectPhaseId) }}</h5>
              </div>
            </div>
          </div>

          <!-- <CoverageView
            v-if="projectLP.project.coverageList.length > 0"
            :coverageList="projectLP.project.coverageList"
          />-->
          <div class="flex display-flex flex-column align-center justify-center">
            <h3
              class="title-container font-destaque f-size-32 color-theme-texto"
            >Captação do projeto</h3>
          </div>
          <DonationsBar
            v-if="projectLP.project.totalValue > 0"
            :project="projectLP.project"
            :colorPrimary="color_primary"
            :colorSecondary="color_secondary"
          />
          <ProjectGalleryView :project="projectLP.project" />

          <!-- CONHEÇA ######################## -->
          <div class="conheca" style="display: none">
            <div class="container">
              <v-layout row wrap>
                <v-flex md12>
                  <h4
                    class="my-5 t-768-center font-destaque f-size-32 color-theme-primaria"
                  >Conheça o projeto</h4>
                </v-flex>
                <v-flex md4 pr-4>
                  <h3 class="mb-4 t-768-center color-theme-texto">Problema Social</h3>
                  <p
                    class="text-justify t-768-center color-theme-texto"
                  >{{ projectLP.project.socialProblem }}</p>
                </v-flex>
                <v-flex md4 pr-4>
                  <h3 class="mb-4 t-768-center color-theme-texto">Solução</h3>
                  <p
                    class="text-justify t-768-center color-theme-texto"
                  >{{ projectLP.project.solution }}</p>
                </v-flex>
                <v-flex md4>
                  <h3 class="mb-4 t-768-center color-theme-texto">Metodologia</h3>
                  <p
                    class="text-justify t-768-center color-theme-texto"
                  >{{ projectLP.project.methodology }}</p>
                </v-flex>
              </v-layout>
            </div>
          </div>

          <ProjectSimulationLP
            v-if="ProjectTypes.ProjetoIncentivado == projectLP.project.projectTypeId"
            :projectLP="projectLP"
            :editing="edit"
            :colorPrimary="color_primary"
            :colorSecondary="color_secondary"
            @toggleSimulation="toggleSimulation"
          />
          <Simulation
            :campaign="campaign"
            :landingPageOptions="landingPageOptions"
            :linkToDonation="this.projectLP.project.id"
            @donate="goToCartWithThisProject"
          />

          <!-- <TargetAudience
            v-if="projectLP.project.directBeneficiaries > 0"
            :projectLP="projectLP"
            :colorPrimary="color_primary"
            :colorSecondary="color_secondary"
          />-->

          <!-- <OurThanks></OurThanks> -->

          <!-- <GoalsView
            v-if="projectLP.project.goalList.length > 0"
            :goalList="projectLP.project.goalList"
            :colorPrimary="color_primary"
          />-->

          <section ref="donationProductBlock" class="bloco projetos bg-theme-white">
            <DonationProducts
              v-if="projectLP.project.donationProductList.length > 0"
              :showDonationButton="showDonationButton"
              :isModal="true"
              :donationProductList="projectLP.project.donationProductList"
              :project="projectLP.project"
              :campaignLandingPage="campaignLandingPage"
            />
          </section>

          <!-- <ActivityView
            v-if="projectLP.project.scheduleList.length > 0"
            :activities="projectLP.project.scheduleList"
            :pinColor="color_secondary"
          />-->

          <!-- <InstitutionAbout :institution="projectLP.project.institution" /> -->

          <!-- <div class="grad">
            <div
              class="gradient-enter"
              :style="{background: 'linear-gradient(225deg, '+color_primary+' 0%, '+color_secondary+' 100%)'}"
            ></div>
            <div class="texto">
              <div class="container">
                <h2>Para empresas</h2>
                <p>
                  Tenha acesso às documentações para sua diligência, aos dados de
                  conta bancária para doações de pessoa jurídica e às contrapartidas
                  do projeto.
                </p>
                <button class="btn-white-black">faça uma simulação</button>
              </div>
            </div>
          </div>-->

          <NewsView
            v-if="projectLP.project.newsList.length > 0"
            :newsList="projectLP.project.newsList"
          />

          <SupportersView
            v-if="projectLP.project.supporterList.length > 0"
            :supporterList="projectLP.project.supporterList"
          />

          <ProjectContactLP
            :institution="projectLP.project.institution"
            :colorPrimary="color_primary"
            :colorSecondary="color_secondary"
          />

          <footer class="display-flex flex-column">
            <div v-if="false" class="bg-white justify-center display-flex">
              <img src="../../../assets/images/logo-auc.svg" alt class="my-5" />
            </div>
            <div class="pa-5 direitos display-flex justify-center bg-theme-primaria">
              <h6
                class="fw-400"
              >{{ projectLP.project.institution.entity.name }} © {{ getActualYear() }} - Todos os direitos reservados</h6>
            </div>
          </footer>
          <div class="container">
            <v-layout row wrap>
              <div class="termo text-italic">
                <div>
                  Powered by
                  <span class="td-u ml-1 p-0">abraceumacausa.com.br</span>
                </div>
                <div>
                  <a class="with-border" @click="showServiceTerms = !showServiceTerms">Termo de uso</a>
                  <a @click="showPolicyTerms = !showPolicyTerms">Política de privacidade</a>
                </div>
              </div>
            </v-layout>
          </div>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script type="plain/text">
import BorderSeparation from "@/components/BorderSeparation.vue";
import FileProjectImage from "@/components/FileProjectImage.vue";
import ProjectHeroLP from "@/components/ProjectHeroLP.vue";
import CoverageView from "@/components/CoverageView.vue";
import DonationsBar from "@/components/DonationsBar.vue";
import UseTermsModal from "@/components/UseTermsModal.vue";
import PrivacyPolicyTermsModal from "@/components/PrivacyPolicyTermsModal.vue";
import OurThanks from "@/components/OurThanks.vue";
import ProjectGalleryView from "@/components/ProjectGalleryView.vue";
import ProjectSimulationLP from "@/components/ProjectSimulationLP.vue";
import Simulation from "@/components/Simulation.vue";
import TargetAudience from "@/components/TargetAudience.vue";
import GoalsView from "@/components/GoalsView.vue";
import ActivityView from "@/components/ActivityView.vue";
import NewsView from "@/components/NewsView.vue";
import InstitutionAbout from "@/components/InstitutionAbout.vue";
import DonationProducts from "@/components/DonationProducts.vue";
import SupportersView from "@/components/SupportersView.vue";
import ProjectContactLP from "@/components/ProjectContactLP.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import FileProject from "@/scripts/models/fileProject.model";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import FileService from "@/scripts/services/file.service";
import FileProjectService from "@/scripts/services/fileProject.service";
import CampaignService from "@/scripts/services/campaign.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { IncentiveLaws } from "@/scripts/models/enums/incentiveLaws.enum.js";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import {
  SocialMedia,
  GetSocialMediaLink
} from "@/scripts/models/enums/socialMedias.enum";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";
import DonationProductsList from "@/components/DonationProductsList.vue";
import moment from 'moment';

export default {
  components: {
    BorderSeparation,
    FileProjectImage,
    ProjectHeroLP,
    CoverageView,
    DonationsBar,
    ProjectGalleryView,
    ProjectSimulationLP,
    PrivacyPolicyTermsModal,
    UseTermsModal,
    Simulation,
    TargetAudience,
    GoalsView,
    InstitutionAbout,
    OurThanks,
    ActivityView,
    NewsView,
    SupportersView,
    ProjectContactLP,
    DonationProducts,
    DonationProductsList
  },
  props: ["projectId", "landingPageOptions"],
  data() {
    return {
      tab: null,
      edit: false,
      items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      fileType: FileType,
      fileProjectList: [],
      ageRangeList: [],
      showPolicyTerms: false,
      showServiceTerms: false,
      campaign: null,
      campaignLandingPage: null,
      project: new Project(),
      projectLP: new ProjectLandingPageConfig(),
      projectService: new ProjectService(),
      fileProject: new FileProject(),
      fileProjectService: new FileProjectService(),
      fileService: new FileService(),
      formatterHelper: new FormatterHelper(),
      selectedFileProjectId: null,
      project: {
        type: "Galeria de Fotos"
      },
      color_primary: "#16AEB5",
      color_secondary: "#D2E793",
      color_alternative: "#D2E793",
      targetAudienceList: [],
      getProjectTypeName: GetProjectTypeName,
      ProjectTypes: ProjectTypes,
      SocialMedia: SocialMedia,
      GetSocialMediaLink: GetSocialMediaLink,
      projectPhasesText: ProjectPhasesText,
      routes: Routes
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService
        .buildProjectLandingPage(to.query.projectId)
        .then(function (data) {
          to.query.projectLP = data;
          if (CommonHelper.getSubdomain()) {
            // new CampaignService().findBySubdomain().then(data => {
            new CampaignService()
              .getCampaignLandingPageConfigBySubdomain()
              .then(data => {
                console.log(data)
                to.query.campaignLandingPage = data;
                to.query.campaign = data.campaign;
                to.query.navFile = data.campaign.file;
                if (data && data.id == 9) {
                  if (typeof Init === "function") {
                    Init(
                      "?botID=30501&appID=",
                      600,
                      600,
                      "https://dvgpba5hywmpo.cloudfront.net/media/image/TYsW63oOYPxbyocUIdDliFOrh",
                      "bubble",
                      "#00AFF0",
                      90,
                      90,
                      62.99999999999999,
                      "",
                      "1",
                      "#FFFFFF",
                      0
                    );
                  }
                } else if (data && data.id == 10) {
                  if (typeof Init === "function") {
                    Init(
                      "?botID=31343&appID=webchat",
                      600,
                      600,
                      "https://dvgpba5hywmpo.cloudfront.net/media/image/3VORB6g4yeFLOb05BNGwG9fly",
                      "bubble",
                      "#000000",
                      90,
                      90,
                      62.99999999999999,
                      "",
                      "1",
                      "#FFFFFF",
                      0
                    );
                  }
                }
                next();
              });
          } else {
            next();
          }
        });
    } else {
      next({ name: Routes.app.DashboardPF });
    }
  },
  computed: {
    showDonationButton() {
      if (this.projectLP && this.projectLP.project && this.campaign) {
        return (
          CommonHelper.getSubdomain() != null &&
          this.projectLP.project.captureStatus &&
          this.campaign.campaignPhaseId == CampaignPhases.EmAndamento &&
          this.campaign.published
        );
      }
      return false;
    },
    shareLink() {
      return window.location.href;
    }
  },
  created() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    this.projectLP = this.$route.query.projectLP;
    this.edit = this.$route.query.edit;
    this.fileProjectService.listByProjectIdAndType(
      this.listFileProjectCallback,
      this.$route.query.projectId,
      this.fileType.ImagemGaleriaProjeto
    );
    this.projectService.listAllTargetAudience(
      this.listAllTargetAudienceCallback
    );
    if (this.$route.query.navFile) {
      this.$set(this.landingPageOptions, "logo", this.$route.query.navFile);
    }
    if (this.$route.query.campaign) {
      this.campaign = this.$route.query.campaign;
    }
    if (this.$route.query.campaignLandingPage) {
      this.campaignLandingPage = this.$route.query.campaignLandingPage;
    }
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      if (element) {
        var topPosition = element.offsetTop - 110;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
        return true;
      }
      return false;
    },
    getTargetAudienceName(targetAudienceId) {
      if (this.targetAudienceList.length > 0 && targetAudienceId != 0)
        return this.targetAudienceList.find(x => x.id === targetAudienceId)
          .name;
    },
    getProjectPhaseName(projectPhaseId) {
      for (let projectPhase in ProjectPhasesText) {
        if (ProjectPhasesText[projectPhase].id == projectPhaseId)
          return ProjectPhasesText[projectPhase].name;
      }
    },
    toggleSimulation() {
      this.landingPageOptions.simulationDrawer = !this.landingPageOptions
        .simulationDrawer;
    },
    listAllTargetAudienceCallback(data) {
      this.targetAudienceList = data;
    },
    convertHex: function (hex, opacity) {
      hex = hex.replace("#", "");
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);

      result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
      return result;
    },
    goToCartWithThisProject(suggestedValue) {
      if (this.projectLP.project.donationProductList.length > 0) {
        this.$refs.listProductsDialog.open(this.projectLP.project);
      } else {
        this.$router.push({
          name: Routes.web.CartLogged,
          query: { projectId: this.projectLP.project.id }
        });
      }
    },
    getActualYear() {
      return moment().format('YYYY');
    }
  }
};
</script>