<template>
  <v-layout>
    <v-dialog v-model="landingPageOptions.simulationDrawer" scrollable max-width="920px">
      <v-card>
        <v-layout o-hidden>
          <v-layout avatar column>
            <v-flex px-4 py-2 simulation-father>
              <button class="close-modal" @click="landingPageOptions.simulationDrawer = false">
                <i class="fal fa-times"></i>
              </button>
              <v-flex shrink simulation-header>
                <h3 class="color-theme-texto">
                  <i class="fal fa-calculator-alt mr-3 color-theme-secundaria"></i>
                  Simule sua doação
                </h3>
                <p class="color-theme-texto">
                  Utilize o simulador para fazer o cálculo aproximado do seu potencial de doação anual que é referente
                  <strong>a {{ campaign ? campaign.simulatorPercentage : 0 }}% do seu imposto de renda devido.</strong>
                </p>
                <p class="ma-0 color-theme-texto">
                  Para sua segurança, nenhuma informação digitada nesse formulário será armazenada, apenas o valor final.
                </p>
                <p>
                  <br />
                  <strong>Importante:</strong>
                  <br />
                  1 - Não esqueça de preencher todas as informações referentes às suas despesas dedutíveis (dependentes, instrução, saúde, previdência etc.). Essas informações são fundamentais para a acuracidade do cálculo.
                  <br />
                  2 - Caso tenha tido alguma alteração salarial no ano vigente, o cálculo do salário mensal deverá ser pela média dos salários dos 12 meses (janeiro a dezembro) do ano vigente.
                </p>
              </v-flex>
              <v-flex shrink form-simulation pb-0>
                <h4 class="color-theme-secundaria">Rendimentos Tributáveis</h4>
                <v-layout wrap row space-around>
                  <v-flex xs12 sm6 md3 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Salário Bruto Mensal"
                      v-model="simulator.monthlySalary"
                      :infoVisible="true"
                      :precision="2"
                      titleInfo="Salário Bruto Mensal"
                      subTitleInfo="Informe o valor do seu salário mensal bruto, antes dos descontos."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md4 pa-1 mr-2 ml-2>
                    <InputMoney
                      type="text"
                      textLabel="Rendimentos Mensais Aluguel"
                      v-model="simulator.monthlyProceeds"
                      ref="monthlyProceeds"
                      :precision="2"
                      :infoVisible="true"
                      titleInfo="Rendimentos Mensais Aluguel"
                      subTitleInfo="Rendimentos mensais aluguel - (i) Insira o valor de receitas recebidas provenientes de aluguéis de imóveis."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md3 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Bônus Anual (Exceto PLR)"
                      ref="annualBonus"
                      :precision="2"
                      v-model="simulator.annualBonus"
                      :infoVisible="true"
                      titleInfo="Bônus Anual (Exceto PLR)"
                      subTitleInfo="Informe o valor do bônus anual recebido no ano vigente. Não considerar PLR (participação em lucros e resultados), dividendos ou distribuição de lucros. Em caso de dúvida, cheque com seu RH."
                    />
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md3 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Pensão Mensal Recebida"
                      ref="pension"
                      :precision="2"
                      v-model="simulator.pension"
                      :infoVisible="true"
                      titleInfo="Pensão Mensal Recebida"
                      subTitleInfo="Informe o valor da pensão alimentícia mensal recebida, desde que o recolhimento do imposto de renda (carnê leão) seja feito em seu CPF. Não se aplica para pensão recebida de não residentes no Brasil."
                    />
                  </v-flex> -->
                </v-layout>
                <h4 class="color-theme-secundaria">Despesas Dedutíveis</h4>
                <v-layout wrap row>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputSelect
                      textLabel="Tem dependentes? Quantos?"
                      ref="childrenQuantity"
                      :items="children"
                      v-model="simulator.childrenQuantity"
                      :infoVisible="true"
                      titleInfo="Tem dependentes? Quantos?"
                      subTitleInfo="Quantos dependentes existem."
                      :autoFocusOnValidationFail="false"
                    ></InputSelect>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Despesa Mensal com Instrução/Educação"
                      ref="monthlyExpense"
                      :precision="2"
                      v-model="simulator.monthlyEducationExpense"
                      :infoVisible="true"
                      titleInfo="Despesa Mensal com Instrução/Educação"
                      subTitleInfo="Informe o valor de sua despesa mensal com instrução/educação com você e seus dependentes."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Despesas Médicas mensais (não reembolsadas)"
                      ref="monthlyMedicalExpense"
                      :precision="2"
                      v-model="simulator.monthlyMedicalExpense"
                      :infoVisible="true"
                      titleInfo="Despesas Médicas mensais (não reembolsadas)"
                      subTitleInfo="Informe as despesas médicas mensais realizadas por você com você e seus dependentes que não foram reembolsadas pelo plano de saúde. Ex.: Valor mensal do convênio médico, residual de consultas e procedimentos (cirurgias, fisioterapia etc)."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Outras deduções mensais"
                      :precision="2"
                      ref="monthlyDeductions"
                      v-model="simulator.otherDeductions"
                      :infoVisible="true"
                      titleInfo="Outras deduções mensais"
                      subTitleInfo="Funpresp, FAPI e Parcela isenta de aposentadoria, reserva remunerada, reforma e pensão para declarante com 65 anos ou mais, caso não tenha sido deduzida dos rendimentos. Carne-Leão: Livro Caixa."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Previdência Privada PGBL (Mensal)"
                      :precision="2"
                      ref="privatePension"
                      v-model="simulator.privatePension"
                      :infoVisible="true"
                      titleInfo="Previdência Privada PGBL (Mensal)"
                      subTitleInfo="Informe o valor que você paga mensalmente de Previdência Privada - PGBL. O valor de dedução é limitado a 12% do valor total dos rendimentos tributáveis. Quem opta pela VGBL não tem deduções pela declaração completa."
                    />
                  </v-flex>
                  <!-- <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Pensão Mensal Paga"
                      :precision="2"
                      ref="alimony"
                      v-model="simulator.alimony"
                      :infoVisible="true"
                      titleInfo="Pensão Mensal Paga"
                      subTitleInfo=" Informe o valor que você paga como pensão alimentícia (mensal), desde que o valor de recolhimento do IR sobre o valor da pensão seja em seu CPF"
                    />
                  </v-flex> -->
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex class="box-simulation p-relative" :class="{active: ShowSimulation}">
              <v-flex class="header-blue-simulation">
                <v-btn
                  v-if="!recalculate"
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="simulate(); $gtag('send', 'event', 'Calcular', 'Simulação', 'Simulação'), showButton=true"
                >Calcular</v-btn>
                <v-btn
                  v-else
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="simulate(), showButton=true"
                >Recalcular</v-btn>
                <div v-if="showButton">
                  <div>
                    <!--v-if="result != 0 || (result == 0 && hasSimulated())"-->
                    <span>Saldo potencial de doação</span>
                    <h4>{{ result | FormatMoney }}</h4>
                  </div>
                  <a href="javascript:;" @click="ShowSimulation = !ShowSimulation ">
                    <i class="fal fa-chevron-up"></i>
                  </a>
                </div>
              </v-flex>
              <v-flex content-simulation bg-alpha v-show="(hasSimulated() || SimulationMessage) && ShowSimulation">
                <h3 v-if="SimulationTitle != ''">
                  <strong>{{SimulationTitle}}</strong>
                </h3>
                <h3>{{ SimulationMessage }}</h3>
                <v-btn
                  v-show="this.linkToDonation != '' && this.linkToDonation != undefined && showDonationButton"
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="gotoDonation()"
                >Doar</v-btn>
              </v-flex>
              <!-- <v-flex content-simulation bg-alpha>
              <h3>Sabe quem ficaria feliz com uma doação assim?</h3>
              <v-flex>
                <span>Instituto de Pessoas contra o câncer</span>
                <div>
                  <v-flex mr-3>
                    <img
                      src="https://images.pexels.com/photos/2253879/pexels-photo-2253879.jpeg"
                      alt
                    />
                  </v-flex>
                  <v-flex column>
                    <p>Conheça Ana Flávia. Professora, ela vive história de amor, fé e luta contra o câncer de mama.</p>
                    <div>
                      <a href="#">Quero doar</a>
                      <a href="#">Conheça a história</a>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-flex>
            <v-flex content-simulation>
              <h3>Projetos recomendados</h3>
              <v-flex>
                <span>ONG Trata Brasil</span>
                <div>
                  <v-flex mr-3>
                    <img
                      src="https://images.pexels.com/photos/2182981/pexels-photo-2182981.jpeg"
                      alt
                    />
                  </v-flex>
                  <v-flex column>
                    <h5>Ecofalante</h5>
                    <p>Conheça Ana Flávia. Professora, ela vive história de amor, fé e luta contra o câncer de mama.</p>
                    <div>
                      <a href="#">Quero doar</a>
                      <a href="#">Conheça a história</a>
                    </div>
                  </v-flex>
                </div>
                <v-flex meet-other-projects>
                  <a href="#">Conheça outros projetos</a>
                </v-flex>
              </v-flex>
              </v-flex>-->
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
      <v-divider></v-divider>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import InputText from "@/components/input/InputText.vue";
import Simulator from "@/scripts/models/simulator.model.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import { isNumber } from "util";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InputText
  },
  props: {
    landingPageOptions: false,
    linkToDonation: "",
    campaign: Object
  },
  created() {},
  mounted() {
    this.filledLocalStorageCache();
    this.setIncomeTax();
  },
  watch: {
    "landingPageOptions.simulationDrawer"() {
      this.filledLocalStorageCache();
      if (this.result && this.result != 0) {
        this.recalculate = true;
        this.showButton = true;
      }
    }
  },
  data() {
    return {
      dialog: true,
      simulator: new Simulator(),
      ShowSimulation: false,
      SimulationMessage: "",
      SimulationTitle: "",
      children: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      result: 0,
      suggestedValue: 0,
      incomeTotal: 0,
      formatterHelper: null,
      testText: "",
      showButton: false,
      showDonationButton: true,
      recalculate: false,
      fontListDestaque: [
        "Alegreya",
        "Eb Garamond",
        "Roboto Slab",
        "Hepta Slab",
        "Zilla Slab",
        "Playfair Display",
        "Montserrat",
        "Raleway",
        "Oswald",
        "Ubuntu",
        "Dosis",
        "Red Hat Display"
      ]
    };
  },
  watch: {
    campaign(value) {
      if (value) {
        this.showDonationButton =
          value.campaignPhaseId == CampaignPhases.EmAndamento &&
          value.published;
      }
    }
  },
  computed: {
    ...mapGetters([
      'incomeTax',
    ]),
  },
  methods: {
    ...mapActions([
      'setIncomeTax',
    ]),
    simulate() {
      //debugger
      this.simulator.childrenQuantity = (this.simulator.childrenQuantity == null) ? 0 : this.simulator.childrenQuantity;

      var outside = this;

      var validatedMonthlySalary = this.simulator.monthlySalary * 12;
      var validatedMonthlyProceeds = this.simulator.monthlyProceeds * 12;
      var validatedAnnualBonus = this.simulator.annualBonus;
      //var validatedPension = this.simulator.pension * 12;
      var validatedMonthlyEducationExpense = this.simulator
        .monthlyEducationExpense;
      var validatedMonthlyMedicalExpense =
        this.simulator.monthlyMedicalExpense * 12;
      var validatedOtherDeductions = this.simulator.otherDeductions * 12;
      var validatedPrivatePension = this.simulator.privatePension * 12;
      //var validatedAlimony = this.simulator.alimony * 12;
      var educationDiscount = outside.calculateEducationDiscount(
        validatedMonthlyEducationExpense * 12,
        this.simulator.childrenQuantity
      ); //Calcula_Abatimento_Educacao

      var properties = [
        validatedMonthlySalary,
        validatedMonthlyProceeds,
        validatedAnnualBonus,
        //validatedPension
      ];

      this.incomeTotal = outside.addProps(properties); //totalRenda

      var privatePension = outside.calculatePrivatePension(
        validatedPrivatePension
      );
      var childDiscount = outside.calculateChildrenDiscount(
        this.simulator.childrenQuantity
      );

      var props = [
        //validatedAlimony,
        educationDiscount,
        validatedMonthlyMedicalExpense,
        privatePension,
        validatedOtherDeductions,
        childDiscount
      ];

      var deductionsTotal = outside.addProps(props);
      var inssTotal = outside.calculateInss(this.simulator.monthlySalary) * 12; //testado até aqui

      var basisCalculation = this.incomeTotal - deductionsTotal - inssTotal;

      var tax = outside.calculateTaxes(basisCalculation);
      this.suggestedValue = Math.round(parseInt(tax * (this.campaign.simulatorPercentage/100)) * 1, 2);

      var bestOption = outside.calculateBestOption(
        this.incomeTotal,
        deductionsTotal,
        inssTotal
      );

      var proceeds = [
        validatedMonthlySalary,
        validatedMonthlyProceeds,
        validatedAnnualBonus,
        //validatedPension
      ];

      var expenses = [
        validatedMonthlyEducationExpense,
        validatedMonthlyMedicalExpense,
        validatedOtherDeductions,
        validatedPrivatePension,
        //validatedAlimony
      ];

      if (outside.checkIfNoneOfValuesIsFilled()) {
        this.SimulationMessage =
          "Você precisa inserir, pelo menos, os valores de seus rendimentos tributáveis.";

        this.result = 0;
        outside.fillLocalStorageCache();
        this.ShowSimulation = true;
        return parseInt(this.result);
      } else if (
        this.checkIfExpensesAreHigherThanProceeds(proceeds, expenses) ||
        this.suggestedValue === 0
      ) {
        this.SimulationMessage =
          "Você não poderá abater suas doações no imposto de renda. " +
          "Porém, você pode doar mesmo assim e acompanhar os impactos que ajudou a gerar.";
        this.SimulationTitle = "Isento de declaração.";

        this.result = 0;
        outside.fillLocalStorageCache();
        this.recalculate = true;
        this.ShowSimulation = true;
        return parseInt(this.result);
      } else if (bestOption === "completa" && this.suggestedValue !== 0) {
        this.SimulationMessage = "Parabéns, este é seu potencial de doação!";
        this.SimulationTitle = "";
      } else {
        this.SimulationMessage =
          "Você não poderá abater suas doações no imposto de renda. " +
          "Porém, você pode doar mesmo assim e acompanhar os impactos que ajudou a gerar";
        this.SimulationTitle = "Declaração simples";
        this.result = 0;
        outside.fillLocalStorageCache();
        this.ShowSimulation = true;
        this.recalculate = true;
        return parseInt(this.result);
      }
      this.result = this.suggestedValue;
      
      outside.fillLocalStorageCache();
      this.ShowSimulation = true;
      this.recalculate = true;
      this.$emit("calculate", this.suggestedValue);
      return parseInt(this.result);
    },
    calculateEducationDiscount(desp_instr) {
      //calcular abatimentos
      //var abatimento_por_dependente = 3561.5;
      var limite_educacao =
        (parseInt(this.simulator.childrenQuantity)) * 3561.5;
      if (desp_instr > limite_educacao) {
        return limite_educacao;
      }
      return desp_instr;
    },
    calculatePrivatePension(private_pension) {
      if (private_pension > this.incomeTotal * 0.12) {
        private_pension = this.incomeTotal * 0.12;
      }
      return private_pension;
    },
    calculateChildrenDiscount() {
      //calcular abatimentos por dependente
      return this.simulator.childrenQuantity * 2275.08;
    },
    addProps(properties) {
      var sum = 0;
      properties.map(function(p) {
        sum += p;
      });
      return sum;
    },
    calculateInss(monthly_salary) {
      var range1 = 1693.72;
      var range2 = 2822.9;
      var range3 = 5645.8;
      var perc1 = 8;
      var perc2 = 9;
      var perc3 = 11;
      if (monthly_salary <= range1) {
        return (monthly_salary * perc1) / 100;
      }
      if (monthly_salary <= range2) {
        return (monthly_salary * perc2) / 100;
      }
      if (monthly_salary < range3) {
        return (monthly_salary * perc3) / 100;
      }
      return (range3 * perc3) / 100;
    },
    calculateTaxes(baseValue) {
      //calcular imposto
      var range1 = 1903.98 * 12;
      var range2 = 2826.65 * 12;
      var range3 = 3751.05 * 12;
      var range4 = 4664.68 * 12;
      var perc1 = 7.5;
      var perc2 = 15.0;
      var perc3 = 22.5;
      var perc4 = 27.5;
      var deduz1 = 142.8 * 12;
      var deduz2 = 354.8 * 12;
      var deduz3 = 636.13 * 12;
      var deduz4 = 869.36 * 12;

      if (baseValue > range4) {
        return (baseValue * perc4) / 100 - deduz4;
      }
      if (baseValue >= range3) {
        return (baseValue * perc3) / 100 - deduz3;
      }
      if (baseValue >= range2) {
        return (baseValue * perc2) / 100 - deduz2;
      }
      if (baseValue >= range1) {
        return (baseValue * perc1) / 100 - deduz1;
      }

      return 0;
    },

    calculateBestOption(calculatedIncome, deducTotal, inss) {
      var discount = calculatedIncome * 0.2;
      if (discount > 16754.34) {
        var discount = 16754.34;
      }
      if (calculatedIncome > 28559.7) {
        if (discount < deducTotal + inss) {
          return "completa";
        }
      }
      if (discount < deducTotal) {
        return "completa";
      } else {
        return "simples";
      }
    },

    checkIfExpensesAreHigherThanProceeds(proceeds, expenses) {
      var procs = this.addProps(proceeds);
      var exps = this.addProps(expenses);

      return exps > procs;
    },

    fillLocalStorageCache() {
      for (const [key, value] of Object.entries(this.simulator)) {
        sessionStorage.setItem(key, value);
      }

      sessionStorage.result = this.result;
    },

    filledLocalStorageCache() {
      for (const [key, value] of Object.entries(this.simulator)) {
        if (key == "childrenQuantity") {
          this.simulator[key] = sessionStorage.getItem(key);
        } else {
          this.simulator[key] = this.$options.filters.FormatMoney(
            sessionStorage.getItem(key)
          );
        }
      }
      this.result = sessionStorage.result;
    },

    checkIfNoneOfValuesIsFilled() {
      for (const [key, value] of Object.entries(this.simulator)) {
        if (value > 0) {
          return false;
        }
      }
      return true;
    },

    hasSimulated() {
      for (const [key, value] of Object.entries(this.simulator)) {
        if (sessionStorage.getItem(key) > 0) {
          return true;
        }
      }
      return false;
    },
    gotoDonation() {
      this.landingPageOptions.simulationDrawer = !this.landingPageOptions
        .simulationDrawer;
      this.$emit("donate", this.suggestedValue);

      // if (!isNumber(this.linkToDonation)) {
      //   this.$emit("scrollToElement");
      // }
      // else if(isNumber(this.linkToDonation) && this.hasdDonationProduct){
      //   this.$emit("goToCart");
      // } else {
      //   this.$router.push({
      //     name: Routes.web.CartLogged,
      //     query: { projectId: this.linkToDonation }
      //   });
      // }
    }
  }
};
</script>